import { LitElement, html, css } from 'lit';
import { backendUrl, notifyToast } from './utils.js';
import '@shoelace-style/shoelace/dist/components/card/card.js';
import '@shoelace-style/shoelace/dist/components/button/button.js';
import '@shoelace-style/shoelace/dist/themes/light.css';
import '@shoelace-style/shoelace/dist/components/input/input.js';
import '@shoelace-style/shoelace/dist/components/alert/alert.js';

class SignUpElement extends LitElement {
    static styles = css`
        .card-container {
            display: flex;
            justify-content: center;
            align-items: center;
        }
        #card-title {
            font-weight: bold;
        }
        .card-header form {
            width: 250px; /* Set a maximum width */
            margin: 0 auto; /* Center the form within the card */
        }
        .card-header form sl-input {
            margin-top: 0.5rem;
        }
        .card-header form sl-button {
            width: 100%;
            margin-top: 0.5rem;
        }
        .card-header {
            width: 250px;
            display: flex; /* Added to enable flexbox centering */
            justify-content: center; /* Center the content horizontally */
            margin: 0 auto;
            box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
        }
    `;

    static properties = {
        isButtonDisabled: { type: Boolean },
    };

    constructor() {
        super();
        this.isButtonDisabled = true;
    }

    firstUpdated() {
        this.loginForm = this.shadowRoot.getElementById('login-form');
        this.loginForm.addEventListener('submit', (e) => {
            e.preventDefault();
            this._sendLink();
        });
    }

    async _sendLink() {
        const button = this.shadowRoot.getElementById('magic-link-button');
        const email = this.shadowRoot.getElementById('email-input').value;

        console.log("Sending magic link...");
        button.disabled = true;

        try {
            const response = await fetch(`${backendUrl}/api/v1/send_link`, {
                method: 'POST',
                body: JSON.stringify({ email: email }),
                headers: { 'Content-Type': 'application/json' }
            });
            const data = await response.json();
            notifyToast(data.message, "success", 10000);
        } catch (error) {
            notifyToast("Error: " + error.message, "danger", 10000);
        } finally {
            button.disabled = false;
        }
    }

    render() {
        return html`
            <div class="card-container">
                <sl-card class="card-header">
                    <span id="card-title">Log in</span>
                    <form id="login-form">
                        <sl-input type="email" id="email-input" placeholder="Email address"  clearable pill required></sl-input>
                        <sl-button type="submit" variant="primary" pill id="magic-link-button">✉ Get a magic link</sl-button>
                    </form>
                </sl-card>
            </div>
        `;
    }
}

customElements.define('sign-up-element', SignUpElement);
